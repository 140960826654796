import { Fragment } from "react";
import Head from "./head";

const Contact = () => {
  return (
    <Fragment>
        <Head />
        
        <div>
          <a href="mailto:info@sordidvibes.com">info@sordidvibes.com</a>
        </div>
        
    </Fragment>
);
}
export default Contact;