import { Fragment } from "react";
import Head from "./head";

const Home = () => {
    return (
        <Fragment>
            <Head homepage={true} />
            <div>
                <img src={"sv_front_1.jpg"} className="front-page-image" alt="individual photos" />
            </div>
        </Fragment>
    );
    }
    
export default Home;