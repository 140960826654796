const Head = (props) => {
    return (
        <table className="head-table"><tbody>
            <tr>
                <td className="desktop-only1">
                <div><a className={"nav-link" + (props.homepage ? " nav-link-homepage" : "")} href="./about">The Vibes</a></div>
                <div><a className={"nav-link" + (props.homepage ? " nav-link-homepage" : "")} href="./tour">Tour</a></div>
                </td>
                <td>
                <img src={"fulllogo_transparent_crop.png"} className={"logo" + (props.homepage ? " logo-homepage" : "")} alt="logo" onClick={()=>{window.location.href='./';}}/>
                </td>
                <td>
                <div><a className={"nav-link mobile-only1" + (props.homepage ? " nav-link-homepage" : "")} href="./about">The Vibes</a></div>
                <div><a className={"nav-link mobile-only1" + (props.homepage ? " nav-link-homepage" : "")} href="/tour">Tour</a></div>
                <div><a className={"nav-link" + (props.homepage ? " nav-link-homepage" : "")} href="./media">Media</a></div>
                <div><a className={"nav-link" + (props.homepage ? " nav-link-homepage" : "")} href="./contact">Contact</a></div>
                </td>
            </tr>
            {!props.homepage && <tr>
                <td>&nbsp;</td>
            </tr>}
        </tbody></table>

      );
    }
    
export default Head;