import { Fragment } from "react";
import Head from "./head";

const Media = () => {
  return (<>
    <Head />
    <div>
      <img src="images/344249302_214646351299018_709337146574868246_n.jpg" style={{width: '25vw', margin: 10}}/>
      <img src="images/344283202_3416408788626642_4254751145680413827_n.jpg" style={{width: '25vw', margin: 10}}/>
    </div>
    <div>
      <img src="images/345253559_5372052136231209_1418368956031095629_n.jpg" style={{width: '25vw', margin: 10}}/>
      <img src="images/345269068_213581351416920_9123540349589193582_n.jpg" style={{width: '25vw', margin: 10}}/>
    </div>
      <div>
      <a href="/">Back</a>
    </div>
    </>
);
  }
   
export default Media;
